import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Input,
  Container,
  Header,
  Segment,
  Button,
  List,
  Grid,
  Image,
  Divider,
  Radio,
  Icon,
} from 'semantic-ui-react'
import Swal from 'sweetalert2'
import { withRouter } from 'react-router'
import firebase from './firebase'
import { Left, Right, Around } from './components'
import qs from 'qs'

const theme = {
  mainColor: '#B4050B',
  subColor: '#707070',
}

// const MASCOT_IMG = 'mascot.png'

const initial = {
  speak: '',
  turn: 0,
  tilt: '',
  goto: '',
  newLocation: '',
}

const App = (props) => {
  const [text, setText] = useState({
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
  })

  const [tiltangle, setTiltangle] = useState('')
  const [savelocation, setSavelocation] = useState('')
  const [locations, setLocations] = useState([])
  const [toggle, setToggle] = useState(false)
  const [rid, setRID] = useState(undefined)

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (query.rid) {
      firebase
        .database()
        .ref(`${query.rid}/remote/isRemoteMode`)
        .once('value')
        .then((snapshot) => {
          setToggle(snapshot.val())
        })
      firebase
        .database()
        .ref(`${query.rid}/remote/location`)
        .on('value', (snapshot) => {
          setLocations(snapshot.val() || [])
        })
      setRID(query.rid)
    }
  }, [query.rid])

  const _handleInputChange = (e) => {
    const { name, value } = e.target
    setText({ ...text, [name]: value })
  }

  const onToggle = async () => {
    if (toggle) {
      firebase
        .database()
        .ref(`${rid}/remote/location`)
        .once('value')
        .then((snapshot) => {
          _setToinitial(snapshot.val())
        })
    } else {
      firebase
        .database()
        .ref(`${rid}/remote/isRemoteMode`)
        .set(!toggle)
        .then(() => {
          setToggle(!toggle)
        })
    }
  }

  const onClickSpeak = async (field) => {
    firebase
      .database()
      .ref(`${rid}/remote/speak`)
      .set(text[field])
      .then(() =>
        setTimeout(
          () => firebase.database().ref(`${rid}/remote/speak`).set(''),
          5000,
        ),
      )
  }

  const onClickTurn = async (degrees) => {
    firebase
      .database()
      .ref(`${rid}/remote/turn`)
      .set(degrees)
      .then(() =>
        setTimeout(
          () => firebase.database().ref(`${rid}/remote/turn`).set(0),
          (Math.abs(degrees) / 90) * 5000,
        ),
      )
  }
  const onClickTilt = async () => {
    firebase.database().ref(`${rid}/remote/tilt`).set(tiltangle)
  }
  const onClickGoto = async (location) => {
    firebase.database().ref(`${rid}/remote/goto`).set(location)
  }
  const onClickSaveLocation = async () => {
    firebase
      .database()
      .ref(`${rid}/remote/newLocation`)
      .set(savelocation)
      .then(() => {
        Swal.fire('Done!', 'Successfully save location.', 'success')
      })
  }

  const _setToinitial = async (location) => {
    firebase
      .database()
      .ref(`${rid}/remote`)
      .set({ isRemoteMode: false, location, ...initial })
      .then(() => {
        setToggle(!toggle)
      })
  }
  return rid ? (
    <>
      <TopBar>
        <Container>
          <FlexTop style={{ padding: '0 1em' }}>
            <Header as="h3" style={{ margin: 0 }}>
              Temi Controller
              <Header.Subheader>For controlling Temi</Header.Subheader>
            </Header>
            <FlexTop>
              <p>เปิดใช้งานรีโมท</p>
              <Radio toggle checked={toggle} onChange={() => onToggle()} />
            </FlexTop>
          </FlexTop>
        </Container>
      </TopBar>
      <Container>
        <Grid columns="equal" divided stackable>
          <Grid.Column>
            <Segment basic>
              <>
                <Grid columns="equal" stackable>
                  {/* <Grid.Column
                      computer="5"
                      tablet="16"
                      mobile="16"
                      textAlign="center"
                    >
                      <Image
                        style={{ width: '110px' }}
                        src={`/${MASCOT_IMG}`}
                        centered
                      />
                      <Header
                        as="h3"
                        textAlign="center"
                        style={{ marginTop: '.2em' }}
                      >
                        ไฟ้โตะ
                        <Header.Subheader>Toyota</Header.Subheader>
                      </Header>
                    </Grid.Column> */}
                  <Grid.Column>
                    <Flex>
                      <Header style={{ margin: '.4em .5em 0 0' }}>Tilt</Header>
                      <Input
                        name="tiltangle"
                        value={tiltangle}
                        placeholder="Tilt angle -30 to 50"
                        style={{ flex: 1, marginRight: '.5em' }}
                        onChange={(e) => setTiltangle(e.target.value)}
                      />
                      <StyledButton onClick={onClickTilt}>Tilt</StyledButton>
                    </Flex>
                    <Flex
                      style={{ marginTop: '2em', justifyContent: 'center' }}
                    >
                      <Card onClick={() => onClickTurn(90)}>
                        {' '}
                        <Left {...theme} /> <Header as="h5">Turn Left</Header>
                      </Card>
                      <Card onClick={() => onClickTurn(-90)}>
                        {' '}
                        <Right {...theme} /> <Header as="h5">Turn Right</Header>
                      </Card>
                      <Card onClick={() => onClickTurn(180)}>
                        {' '}
                        <Around {...theme} />{' '}
                        <Header as="h5">Turn Around</Header>
                      </Card>
                    </Flex>
                  </Grid.Column>
                </Grid>
                <Divider />
                <Flex>
                  <Header style={{ margin: '.4em .5em 0 0' }}>Location</Header>
                  <Input
                    name="savelocation"
                    value={savelocation}
                    placeholder="location name"
                    style={{ flex: 1, marginRight: '0.3em' }}
                    onChange={(e) => setSavelocation(e.target.value)}
                  />
                  <StyledButton onClick={onClickSaveLocation}>
                    Save Location
                  </StyledButton>
                </Flex>
                {/* <Button basic fluid onClick={onClickReqLocations}>
                    Request Location
                  </Button> */}
                <List relaxed verticalAlign="middle">
                  {locations.map((e, idx) => (
                    <List.Item keys={idx} key={idx}>
                      <List.Content floated="right">
                        <StyledButton onClick={() => onClickGoto(e)}>
                          Go
                        </StyledButton>
                        {/* <Button content="Delete" /> */}
                      </List.Content>
                      <Image src="/location.svg" />
                      <List.Content>{e}</List.Content>
                    </List.Item>
                  ))}
                </List>
              </>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic>
              <Header>Text To Speech</Header>
              <Input
                name="text1"
                value={text.text1}
                placeholder="ข้อความ"
                fluid
                onChange={_handleInputChange}
              />
              <Flex justifyContent="space-between" marginTop="1em">
                <AudioImage src="/audio.svg" style={{ width: 30 }} />
                <div>
                  <StyledButton onClick={() => onClickSpeak('text1')}>
                    Speak
                  </StyledButton>
                  {/* <Button onClick={() => onClickSpeakEng('text1')}>Speak in English accent</Button> */}
                </div>
              </Flex>
              <Input
                name="text2"
                value={text.text2}
                placeholder="ข้อความ"
                fluid
                onChange={_handleInputChange}
              />
              <Flex justifyContent="space-between" marginTop="1em">
                <AudioImage src="/audio.svg" style={{ width: 30 }} />
                <div>
                  <StyledButton onClick={() => onClickSpeak('text2')}>
                    Speak
                  </StyledButton>
                  {/* <Button onClick={() => onClickSpeakEng('text2')}>Speak in English accent</Button> */}
                </div>
              </Flex>
              <Input
                name="text3"
                value={text.text3}
                placeholder="ข้อความ"
                fluid
                onChange={_handleInputChange}
              />
              <Flex justifyContent="space-between" marginTop="1em">
                <AudioImage src="/audio.svg" style={{ width: 30 }} />
                <div>
                  <StyledButton onClick={() => onClickSpeak('text3')}>
                    Speak
                  </StyledButton>
                  {/* <Button onClick={() => onClickSpeakEng('text3')}>Speak in English accent</Button> */}
                </div>
              </Flex>
              <Input
                name="text4"
                value={text.text4}
                placeholder="ข้อความ"
                fluid
                onChange={_handleInputChange}
              />
              <Flex justifyContent="space-between" marginTop="1em">
                <AudioImage src="/audio.svg" style={{ width: 30 }} />
                <div>
                  <StyledButton onClick={() => onClickSpeak('text4')}>
                    Speak
                  </StyledButton>
                  {/* <Button onClick={() => onClickSpeakEng('text4')}>Speak in English accent</Button> */}
                </div>
              </Flex>
              <Input
                name="text5"
                value={text.text5}
                placeholder="ข้อความ"
                fluid
                onChange={_handleInputChange}
              />
              <Flex justifyContent="space-between" marginTop="1em">
                <AudioImage src="/audio.svg" style={{ width: 30 }} />
                <div>
                  <StyledButton onClick={() => onClickSpeak('text5')}>
                    Speak
                  </StyledButton>
                  {/* <Button onClick={() => onClickSpeakEng('text5')}>Speak in English accent</Button> */}
                </div>
              </Flex>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </>
  ) : (
    <FullPage>
      <div>
        <Icon name="close" size="huge" color="red" />
        <h2>Oops! Something went wrong, please try agian.</h2>
      </div>
    </FullPage>
  )
}

export default withRouter(App)

const FullPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Flex = styled.div`
  display: flex;
  margin-bottom: 1.5em;
  margin-top: ${(props) => props.marginTop || 0};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  @media screen and (max-width: 768px) {
    display: block;
    .input {
      width: 100% !important;
      margin-bottom: 0.5em;
    }
    button {
      width: 100% !important;
      margin-bottom: 0.5em !important;
    }
  }
`

const FlexTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0 1em 0 0;
  }
  @media screen and (max-width: 768px) {
    display: block;
    h3 {
      text-align: center;
      margin: 0 0 1em 0 !important;
    }
    div {
      width: 100%;
    }
  }
`

const TopBar = styled.div`
  width: 100vw;
  box-shadow: 0px 2px 8px #0000000f;
  background: #fff;
  padding: 1.2rem 0;
  margin-bottom: 2em;
`

const StyledButton = styled(Button)`
  background-color: ${theme.mainColor} !important;
  color: rgba(251, 251, 251, 1) !important;
  display: ${(props) => (props.hide ? 'none !important' : 'initial')};
`

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.8em;
  width: 30%;
  max-width: 105px;
  padding: 1.2em 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
`

const AudioImage = styled(Image)`
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`
