import firebase from 'firebase/app'
import 'firebase/database'
const config = {
  apiKey: "AIzaSyC1bMUNbWogYwAXHqXE5_xFhJI4fTw239Q",
  authDomain: "chochae-robot-temi-v2.firebaseapp.com",
  databaseURL: "https://chochae-robot-temi-v2.firebaseio.com",
  projectId: "chochae-robot-temi-v2",
  storageBucket: "chochae-robot-temi-v2.appspot.com",
  messagingSenderId: "262026582264",
  appId: "1:262026582264:web:0b9abc97257cc15dd6830d",
  measurementId: "G-S94RT260BH"
}
firebase.initializeApp(config)
export default firebase
