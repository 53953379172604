import styled from 'styled-components';
import React from 'react';

export const Left = (props) => (
  <Wrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="38" viewBox="0 0 48.68 53.262">
      <g transform="translate(-34 -1)">
        <path className="a" d="M102.116,97.79H96.065v-5.6c0-18.526-16.287-33.6-36.308-33.6v-5.6c23.358,0,42.359,17.583,42.359,39.2Z" transform="translate(-19.436 -43.528)" />
        <path className="b" d="M11.2,23.531,0,12.332l11.2-11.2a2.8,2.8,0,1,1,3.959,3.959l-7.241,7.24,7.241,7.24A2.8,2.8,0,0,1,11.2,23.531Zm0,0" transform="translate(34 0.687)" />
      </g>
    </svg>
  </Wrapper>
)

export const Right = (props) => (
  <Wrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="38" viewBox="0 0 49.161 53.262">
      <g transform="translate(-34 -1)">
        <path className="a" d="M59.758,97.79h6.048v-5.6c0-18.526,16.278-33.6,36.287-33.6v-5.6c-23.344,0-42.334,17.583-42.334,39.2Z" transform="translate(-25.758 -43.528)" />
        <path className="b" d="M5.16,23.531l12.1-11.2L5.16,1.132a3.2,3.2,0,0,0-4.276,0,2.658,2.658,0,0,0,0,3.959l7.82,7.24-7.82,7.24a2.658,2.658,0,0,0,0,3.959A3.2,3.2,0,0,0,5.16,23.531Zm0,0" transform="translate(65.905 0.687)" />
      </g>
    </svg>
  </Wrapper>
)

export const Around = (props) => (
  <Wrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="0 0 51.422 48">
      <g transform="translate(-1606.939 -2970.723)">
        <ellipse style={{ fill: 'none' }} cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(1609.361 2970.723)" />
        <path className="a" d="M1633.861,3018.723c-13.51,0-24.5-10.766-24.5-24a23.883,23.883,0,0,1,10.558-19.737l2.808,4.137a18.89,18.89,0,0,0-8.366,15.6,19.507,19.507,0,1,0,19.5-19v-5c13.51,0,24.5,10.767,24.5,24S1647.371,3018.723,1633.861,3018.723Z" />
        <path className="b" d="M1610.091,2972.2l15.7,2.168-2.168,15.7a2.8,2.8,0,0,1-5.564-.655c0-.038.009-.075.015-.112l1.4-10.148-10.148-1.4a2.8,2.8,0,0,1,.766-5.55Z" />
      </g>
    </svg>
  </Wrapper>
)

const Wrapper = styled.div`
  .a {
    fill: ${props => props.mainColor || 'black'};
  }
  .b {
    fill: ${props => props.subColor || 'grey'};
  }
`;